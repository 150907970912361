<template>
	<div class="createTeamForm">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
		  <el-form-item :label="$t('teamname')" prop="teamName">
		    <el-input v-model="ruleForm.teamName" :placeholder="$t('teamnamesuit')"></el-input>
		  </el-form-item>
		  <el-form-item :label="$t('creater')" prop="createdMen">
		    <el-input v-model="ruleForm.createdMen" placeholder="Martha Campbell" disabled></el-input>
		  </el-form-item>
		  <el-form-item style="display: flex;justify-content: center;">
		    <el-button type="primary" @click="submitForm('ruleForm')">{{$t("createtext")}}</el-button>
		  </el-form-item>
		</el-form>
		
	</div>
</template>

<script>
	import { getMyInfo } from "@/utils/common/user";
	import { createGroup,myInfo } from "@/api/Mine"
	
	export default {
		props: {},
		data(){
			return {
				ruleForm: {
				  teamName: '',
				  createdMen: ''
				},
				rules: {
				  teamName: [
				    { message: this.$t("teamnamesuit"), trigger: 'blur'},
				    {required: true, message: this.$t("minsix"), trigger: 'blur'}
				  ],
				  createdMen: [
				    {required: true, message: this.$t("placeholder") + this.$t("creater"), trigger: 'change'}
				  ],
				},
				infoObj: {}
			}
		},
		created() {
			// let userInfo = JSON.parse(this.getMyInfo())
			// console.log(userInfo,'userInfouserInfouserInfo1111111111111');
			// this.ruleForm.createdMen = userInfo.name
			this.MyInfo()
		},
		methods: {
			MyInfo(){
				myInfo({}).then((res) => {
				      console.log(res.data.name,'我的信息成功');
					  this.infoObj = res.data
					  this.$store.state.bankList = res.data.bankcard
					  this.$store.state.groupType = res.data.group_type
					  this.$store.state.isBoss = res.data.is_boss
					  this.$store.state.groupId = res.data.group_id
					  this.ruleForm.createdMen = res.data.name
					  localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					  // console.log(this.$store.state.groupType,'userInfouserInfouserInfo222222222222')
					  console.log(this.infoObj,'JSON.stringify(this.infoObj)');
					  // if(this.$store.state.groupType == 0){
					  // 	this.tabsList = this.tabsList1
					  // }else{
					  // 	this.tabsList = this.tabsList2
					  // }
				    })
				    .catch((err) => {
				      console.log(err,'我的信息失败');
				    });
			},
			getMyInfo,
			submitForm() {
			  this.$refs['ruleForm'].validate((valid) => {
			    if (valid) {
			      
			        createGroup({
						UserGroup: this.ruleForm.teamName
					}).then(res => {
			          this.$message.success(res.msg)
					  this.getMyInfoObj();
					  
					  // this.$router.go(0)
					  
			          // this.goLogin()
			        }).catch(err => {
			          console.log('注册失败:', err)
			        })
			      //   this.$message.warning(this.$t('agreeToTheAgreement'))
			      
			      // this.loading = true
			      // console.log(this.treatyChecked)
			    } else {
			      return false;
			    }
			  });
			},
			getMyInfoObj(){
				myInfo({}).then((res) => {
				      console.log(res,'我的信息成功');
					  this.infoObj = res.data
					  this.$store.state.bankList = res.data.bankcard
					  this.$store.state.groupType = res.data.group_type
					  localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					  // this.$router.go(0)
					   this.$router.push({
					  //  	path: '/personalCenter/teamManagement/managingMembers',
					  						// '/personalCenter/teamManagement/managingMembers'
					   	path: '/personalCenter/teamManagement/managingMembers'
					   })
					  // console.log(this.$store.state.groupType,'userInfouserInfouserInfo222222222222')
					  console.log(this.infoObj,'JSON.stringify(this.infoObj)');
					  // if(this.$store.state.groupType == 0){
					  // 	this.tabsList = this.tabsList1
					  // }else{
					  // 	this.tabsList = this.tabsList2
					  // }
				    })
				    .catch((err) => {
				      console.log(err,'我的信息失败');
				    });
			}
		}
	}
</script>

<style lang="less" scoped>
	// ::v-deep .el-textarea__inner{
	// 	width: 400px !important;
	// 	height: 128px;
	// 	background:rgba(0,0,0,0);
	// 	border: none;
	// 	resize: none;
	// }
	// ::v-deep .el-input__count{
	// 	background: rgba(0,0,0,0);
	// }
	// ::v-deep .el-form{
	// 	/* margin: auto; */
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-items: center;
	// }
	::v-deep .el-button--primary{
		width: 140px;
		height: 38px;
		background: #3370FF;
		border-radius: 6px;
		border: none;
		margin-top: 40px;
	}
	
	.createTeamForm{
		width: 100%;
		height: 650px;
		background: @recommendMusicianListBGColor;
		border-radius: 6px;
		backdrop-filter: blur(50px);
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		::v-deep .el-input__count{
			background: rgba(0,0,0,0);
		}
		::v-deep .el-input {
			width: 400px;
			background: @recommendMusicianListBGColor;
			border: none;
			color: @uploadFormTextColor;
		}
		::v-deep .el-input__inner{
			width: 400px !important;
			background: rgba(34, 36, 38, 0.0500);
			border: none;
			color: @uploadFormTextColor;
		}
		::v-deep .el-form-item__label{
			color: @recommendMusicianListTextColor;
		}
		.demo-ruleForm{
			margin-top: 80px;
			
		}
		
	}
</style>